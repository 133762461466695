<template>
  <div>平台</div>
</template>

<script>
export default {
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>